.header-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    max-width: 100rem;
    align-items: center;
    position: absolute;

    overflow: hidden;
}

.header-text-image {
    position: relative;
    flex-grow: 1;
    align-items: center;

    width: 100%;
}

.header-image-container{
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    pointer-events: none;

}

.header-logo {
    border: 10px solid grey;
    position: absolute;
    width: 60rem;
    margin-top: 5rem;
    margin-right: 3rem;
    pointer-events: none;

    right: 0;
    overflow: hidden;
    align-items: center;
    animation: slideInRight;
    animation-duration: 2s;
}

.header-text {
    height: 100%;
    margin-left: 3rem;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    z-index: 1;
}

.header-text > h1 {
    font-size: 10rem;
    color: #DC5D6A;
    font-weight: bold;
    animation: bounceIn; /* referring directly to the animation's @keyframe declaration */
    animation-duration: 1s; /* don't forget to set a duration! */
}

.header-text > p {
    font-size: 5rem;
    color: white;
    animation: zoomIn;
    animation-duration: 2s;
}

.header-scrollbutton-container {
    width: 100%;
}

@media screen and (max-width: 992px) {

    .header-container {
        flex-direction: column;
        position: absolute;
        height: 100%;
    }

    .header-text-image {
        position: relative;
        flex-grow: 1;
        align-items: center;
    }

    .header-image-container{
        flex-direction: column;
        align-items: center;
    }

    .header-logo {
        margin-right: 0;
        width: 100%;
        top: 5rem;
    }

    .header-text {
        width: 100%;
        margin-left: 0;
    }

    .header-text > h1 {
        font-size: 6rem;
    }

    .header-text > p {
        font-size: 2rem;
    }
}

@media screen and (max-width: 768px) {
    .header-text > h1 {
        font-size: 3rem;
    }

    .header-text > p {
        font-size: 1.3rem;
    }
}

@media screen and (orientation:landscape) and (max-height: 768px) {
    .header-container {
        flex-direction: row;
        position: absolute;
        height: 100%;
    }

    .header-text > h1 {
        font-size: 3rem;
    }

    .header-text > p {
        font-size: 1.3rem;
    }

    .header-logo {
        position: relative;
    }
}