.app-header {
  display: flex;
  justify-content: center;
  background-color: #252628;
  min-height: 100vh;
}

.app-content {
  display: flex;
  justify-content: center;
}

.content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bg-nav {
  background-color: #252628;
}
